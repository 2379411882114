export const VARIABLE_CREATE_REQUEST = 'VARIABLE_CREATE_REQUEST'
export const VARIABLE_CREATE_SUCCESS = 'VARIABLE_CREATE_SUCCESS'
export const VARIABLE_CREATE_FAIL = 'VARIABLE_CREATE_FAIL'

export const VARIABLE_LIST_REQUEST = 'VARIABLE_LIST_REQUEST'
export const VARIABLE_LIST_SUCCESS = 'VARIABLE_LIST_SUCCESS'
export const VARIABLE_LIST_FAIL = 'VARIABLE_LIST_FAIL'

export const VARIABLE_DELETE_REQUEST = 'VARIABLE_DELETE_REQUEST'
export const VARIABLE_DELETE_SUCCESS = 'VARIABLE_DELETE_SUCCESS'
export const VARIABLE_DELETE_FAIL = 'VARIABLE_DELETE_FAIL'

export const VARIABLE_DETAILS_REQUEST = 'VARIABLE_DETAILS_REQUEST'
export const VARIABLE_DETAILS_SUCCESS = 'VARIABLE_DETAILS_SUCCESS'
export const VARIABLE_DETAILS_FAIL = 'VARIABLE_DETAILS_FAIL'
export const VARIABLE_DETAILS_RESET = 'VARIABLE_DETAILS_RESET'

export const VARIABLE_UPDATE_REQUEST = 'VARIABLE_UPDATE_REQUEST'
export const VARIABLE_UPDATE_SUCCESS = 'VARIABLE_UPDATE_SUCCESS'
export const VARIABLE_UPDATE_FAIL = 'VARIABLE_UPDATE_FAIL'
export const VARIABLE_UPDATE_RESET = 'VARIABLE_UPDATE_RESET'
