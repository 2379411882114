export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const CART_SAVE_SHIPPING_ADDRESS_REQUEST =
  "CART_SAVE_SHIPPING_ADDRESS_REQUEST";
export const CART_SAVE_SHIPPING_ADDRESS_SUCCESS =
  "CART_SAVE_SHIPPING_ADDRESS_SUCCESS";
export const CART_SAVE_SHIPPING_ADDRESS_FAIL =
  "CART_SAVE_SHIPPING_ADDRESS_FAIL";
export const CART_SAVE_SHIPPING_ADDRESS_RESET =
  "CART_SAVE_SHIPPING_ADDRESS_RESET";

export const CART_SAVE_CONTACT_DATA_REQUEST = "CART_SAVE_CONTACT_DATA_REQUEST";
export const CART_SAVE_CONTACT_DATA_SUCCESS = "CART_SAVE_CONTACT_DATA_SUCCESS";
export const CART_SAVE_CONTACT_DATA_FAIL = "CART_SAVE_CONTACT_DATA_FAIL";
export const CART_SAVE_CONTACT_DATA_RESET = "CART_SAVE_CONTACT_DATA_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";
export const ADD_TO_WISHLIST_RESET = "ADD_TO_WISHLIST_RESET";

export const REMOVE_WISHLIST_REQUEST = "REMOVE_WISHLIST_REQUEST";
export const REMOVE_WISHLIST_SUCCESS = "REMOVE_WISHLIST_SUCCESS";
export const REMOVE_WISHLIST_FAIL = "REMOVE_WISHLIST_FAIL";
export const REMOVE_WISHLIST_RESET = "REMOVE_WISHLIST_RESET";

export const LIST_WISHLIST_REQUEST = "LIST_WISHLIST_REQUEST";
export const LIST_WISHLIST_SUCCESS = "LIST_WISHLIST_SUCCESS";
export const LIST_WISHLIST_FAIL = "LIST_WISHLIST_FAIL";
export const LIST_WISHLIST_RESET = "LIST_WISHLIST_RESET";

export const USER_DATA_UPDATE_REQUEST = "USER_DATA_UPDATE_REQUEST";
export const USER_DATA_UPDATE_SUCCESS = "USER_DATA_UPDATE_SUCCESS";
export const USER_DATA_UPDATE_FAILURE = "USER_DATA_UPDATE_FAILURE";
export const USER_DATA_UPDATE_RESET = "USER_DATA_UPDATE_RESET";

export const SET_TOKEN = "SET_TOKEN";

// TODO
export const DELETE_USER_DATA_REQUEST = "DELETE_USER_DATA_REQUEST";
export const DELETE_USER_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";
export const DELETE_USER_DATA_FAILURE = "DELETE_USER_DATA_FAILURE";
