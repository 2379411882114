export const UPLOAD_CATEGORY_IMAGE_REQUEST = 'UPLOAD_CATEGORY_IMAGE_REQUEST'
export const UPLOAD_CATEGORY_IMAGE_SUCCESS = 'UPLOAD_CATEGORY_IMAGE_SUCCESS'
export const UPLOAD_CATEGORY_IMAGE_FAIL = 'UPLOAD_CATEGORY_IMAGE_FAIL'
export const UPLOAD_CATEGORY_IMAGE_RESET = 'UPLOAD_CATEGORY_IMAGE_RESET'

export const REMOVE_CATEGORY_IMAGE_REQUEST = 'REMOVE_CATEGORY_IMAGE_REQUEST'
export const REMOVE_CATEGORY_IMAGE_SUCCESS = 'REMOVE_CATEGORY_IMAGE_SUCCESS'
export const REMOVE_CATEGORY_IMAGE_FAIL = 'REMOVE_CATEGORY_IMAGE_FAIL'
export const REMOVE_CATEGORY_IMAGE_RESET = 'REMOVE_CATEGORY_IMAGE_RESET'

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'
export const CATEGORY_DETAILS_RESET = 'CATEGORY_DETAILS_RESET'

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL'
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET'

export const PRODUCT_BY_CATEGORY_REQUEST = 'PRODUCT_BY_CATEGORY_REQUEST'
export const PRODUCT_BY_CATEGORY_SUCCESS = 'PRODUCT_BY_CATEGORY_SUCCESS'
export const PRODUCT_BY_CATEGORY_FAIL = 'PRODUCT_BY_CATEGORY_FAIL'
export const PRODUCT_BY_CATEGORY_RESET = 'PRODUCT_BY_CATEGORY_RESET'
