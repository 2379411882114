import { getAuth } from "firebase/auth";
import store from "../redux/store";
import axios from "axios";
import { setToken } from "../redux/actions/userActions";
export const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = store.getState()?.userLogIn?.userInfo?.token ?? "";
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      console.log('retry');
      originalRequest._retry = true;
      const token = await getAuth().currentUser.getIdToken(true);
      store.dispatch(setToken(token));

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
