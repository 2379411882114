import {
  SETTINGS_LIST_FAIL,
  SETTINGS_LIST_REQUEST,
  SETTINGS_LIST_SUCCESS,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_SUCCESS,
} from "../constants/settingsConstants";

const initState = {
  deliveryAvailForce: false,
  websiteAvailForce: false,
  loaded: false,
};

export const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    case SETTINGS_LIST_REQUEST:
    case SETTINGS_UPDATE_REQUEST:
      return { ...state, loading: true, loaded: true, error: undefined };

    case SETTINGS_LIST_SUCCESS:
    case SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: undefined,
        deliveryAvailForce: action.payload.deliveryAvailForce,
        websiteAvailForce: action.payload.websiteAvailForce,
        deliveryAvail: action.payload.deliveryAvail,
        websiteAvail: action.payload.websiteAvail,
        bonusConvert: action.payload.bonusConvert,
        deliveryMaxDistance: action.payload.deliveryMaxDistance,
        deliveryKmPrice: action.payload.deliveryKmPrice,
        deliveryMinimumCommande: action.payload.deliveryMinimumCommande,
        deliveryMinimumPrice: action.payload.deliveryMinimumPrice,
      };

    case SETTINGS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SETTINGS_UPDATE_FAIL:
      return { ...state, loading: false, error: undefined };
    default:
      return state;
  }
};
