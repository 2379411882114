import {
  NEWSLETTER_ISSUBSCRIBE_REQUEST,
  NEWSLETTER_ISSUBSCRIBE_SUCCESS,
  NEWSLETTER_ISSUBSCRIBE_FAIL,
  NEWSLETTER_SUBSCRIBE_REQUEST,
  NEWSLETTER_SUBSCRIBE_SUCCESS,
  NEWSLETTER_SUBSCRIBE_FAIL,
  NEWSLETTER_UNSUBSCRIBE_REQUEST,
  NEWSLETTER_UNSUBSCRIBE_SUCCESS,
  NEWSLETTER_UNSUBSCRIBE_FAIL,
} from "../constants/newsletterConstants";

const initState = {
  isSubscribed: undefined,
  loadingIsSubscribed: false,
  loadingSubscribe: false,
  loadingUnSubscribe: false,
  errorIsSubscribed: undefined,
  errorSubscribe: undefined,
  errorUnSubscribe: undefined,
};

export const newsletterReducer = (state = initState, action) => {
  switch (action.type) {
    // issubscribe
    case NEWSLETTER_ISSUBSCRIBE_REQUEST:
      return {
        ...state,
        loadingIsSubscribed: true,
        errorIsSubscribed: undefined,
      };

    case NEWSLETTER_ISSUBSCRIBE_SUCCESS:
      return {
        ...state,
        loadingIsSubscribed: false,
        isSubscribed: action.payload,
        errorIsSubscribed: undefined,
      };

    case NEWSLETTER_ISSUBSCRIBE_FAIL:
      return {
        ...state,
        loadingIsSubscribed: false,
        errorIsSubscribed: action.payload,
      };
    // unsubscribe
    case NEWSLETTER_UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        loadingUnSubscribe: true,
        errorUnSubscribe: undefined,
      };

    case NEWSLETTER_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubscribed: false,
        loadingUnSubscribe: false,
        errorUnSubscribe: undefined,
      };

    case NEWSLETTER_UNSUBSCRIBE_FAIL:
      return {
        ...state,
        loadingUnSubscribe: false,
        errorUnSubscribe: action.payload,
      };
    // subscribe
    case NEWSLETTER_SUBSCRIBE_REQUEST:
      return {
        ...state,
        loadingSubscribe: true,
        errorSubscribe: undefined,
      };

    case NEWSLETTER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubscribed: true,
        loadingSubscribe: false,
        errorSubscribe: undefined,
      };

    case NEWSLETTER_SUBSCRIBE_FAIL:
      return {
        ...state,
        loadingSubscribe: false,
        errorSubscribe: action.payload,
      };

    default:
      return state;
  }
};
