import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ScrollToTop from "./utils/scrollToTop";
import CookieConsent from "react-cookie-consent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer 
          position="top-center" autoClose={2000} pauseOnHover={false} pauseOnFocusLoss={false} draggable={true} style={{marginTop:80}}/>
        <App />
        {/* <CookieConsent
          buttonText=""
          location="bottom"
          visible="show"
          buttonStyle={{display: 'none'}}   
        >Notre site est en cours de test. Nous ne pouvons pas honorer les commandes pour l'instant.</CookieConsent> */}
        {/* <CookieConsent
          buttonText="Ok"
           location="bottom"
        >Ce site utilise des cookies pour améliorer l'expérience de l'utilisateur.</CookieConsent> */}
      </BrowserRouter>
    </Provider>
  </AlertProvider>
  
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
