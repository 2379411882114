export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_CLEAR_ITEM = "CART_CLEAR_ITEM";
export const SET_CART_QTY = "SET_CART_QTY";

export const CART_DB_REQUEST = "CART_DB_REQUEST";
export const CART_DB_SUCCESS = "CART_DB_SUCCESS";
export const CART_DB_FAIL = "CART_DB_FAIL";
export const CART_DB_RESET = "CART_DB_RESET";

export const CART_LIST_REQUEST = "CART_LIST_REQUEST";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAIL = "CART_LIST_FAIL";
export const CART_LIST_RESET = "CART_LIST_RESET";

export const DB_CART_CLEAR_REQUEST = "DB_CART_CLEAR_REQUEST";
export const DB_CART_CLEAR_SUCCESS = "DB_CART_CLEAR_SUCCESS";
export const DB_CART_CLEAR_FAIL = "DB_CART_CLEAR_FAIL";
export const DB_CART_CLEAR_RESET = "DB_CART_CLEAR_RESET";

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAIL = "APPLY_COUPON_FAIL";
export const APPLY_COUPON_RESET = "APPLY_COUPON_RESET";

export const CANCEL_COUPON_REQUEST = "CANCEL_COUPON_REQUEST";
export const CANCEL_COUPON_SUCCESS = "CANCEL_COUPON_SUCCESS";
export const CANCEL_COUPON_FAIL = "CANCEL_COUPON_FAIL";
export const CANCEL_COUPON_RESET = "CANCEL_COUPON_RESET";
