export const COUPON_CREATE_REQUEST = 'COUPON_CREATE_REQUEST'
export const COUPON_CREATE_SUCCESS = 'COUPON_CREATE_SUCCESS'
export const COUPON_CREATE_FAIL = 'COUPON_CREATE_FAIL'
export const COUPON_CREATE_RESET = 'COUPON_CREATE_RESET'

export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST'
export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS'
export const COUPON_LIST_FAIL = 'COUPON_LIST_FAIL'
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET'

export const COUPON_DELETE_REQUEST = 'COUPON_DELETE_REQUEST'
export const COUPON_DELETE_SUCCESS = 'COUPON_DELETE_SUCCESS'
export const COUPON_DELETE_FAIL = 'COUPON_DELETE_FAIL'
export const COUPON_DELETE_RESET = 'COUPON_DELETE_RESET'
