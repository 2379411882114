import axios from "axios";
import { CART_LIST_RESET } from "../constants/cartConstants";
import {
  ADMIN_ORDER_LIST_RESET,
  USER_ORDER_LIST_RESET,
} from "../constants/orderConstants";
import {
  ADD_TO_WISHLIST_FAIL,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  CART_SAVE_SHIPPING_ADDRESS_FAIL,
  CART_SAVE_SHIPPING_ADDRESS_REQUEST,
  CART_SAVE_SHIPPING_ADDRESS_SUCCESS,
  CART_SAVE_CONTACT_DATA_REQUEST,
  CART_SAVE_CONTACT_DATA_SUCCESS,
  CART_SAVE_CONTACT_DATA_FAIL,
  LIST_WISHLIST_FAIL,
  LIST_WISHLIST_REQUEST,
  LIST_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAIL,
  REMOVE_WISHLIST_REQUEST,
  REMOVE_WISHLIST_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_DATA_UPDATE_REQUEST,
  USER_DATA_UPDATE_SUCCESS,
  USER_DATA_UPDATE_FAILURE,
  SET_TOKEN,
} from "../constants/userConstants";
import { auth } from "../../config/firebase";
// import OneSignal from "react-onesignal";

export const setToken = (token) => (dispatch) => {
  dispatch({
    type: SET_TOKEN,
    token,
  });
  window.localStorage.setItem(
    "userInfo",
    JSON.stringify({ ...JSON.parse(window.localStorage.getItem("userInfo")), token })
  );
};

export const logInUser = (user) => async (dispatch) => {
  try {
    // console.log("__USERRRR", user);
    dispatch({ type: USER_LOGIN_REQUEST });

    const { token } = await user.getIdTokenResult();
    // console.log("___USERRRR", user);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL_URL}auth/create-or-update-user`,
      {},
      config
    );
    // try {
    // await OneSignal.login(data.email).catch((e)=>console.log(e));
    // }catch(e) {}
    const { email, name, _id, role, shipping, phone, firebaseUID } = data;
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { _id, name, email, phone, token, role, shipping, firebaseUID },
    });
    window.localStorage.setItem(
      "userInfo",
      JSON.stringify({ _id, name, email, role, token, shipping, firebaseUID })
    );


  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const currentUser = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL_URL}auth/current-user`,
    {},
    config
  );
// try {
//   await OneSignal.login(data.email).catch((e)=>console.log(e));
// }catch(e) {}
  const { email, name, _id, role, shipping } = data;
  window.localStorage.setItem(
    "userInfo",
    JSON.stringify({ _id, name, email, role, token, shipping })
  );
  return data;
};

export const logOut = () => async (dispatch) => {
  await auth.signOut();
//   try {
//   await OneSignal.logout().catch((e)=>console.log(e));
// }catch(e) {}
  await dispatch({ type: USER_LOGOUT });
  await dispatch({ type: USER_LIST_RESET });
  await dispatch({ type: CART_LIST_RESET });
  await dispatch({ type: USER_ORDER_LIST_RESET });
  await dispatch({ type: ADMIN_ORDER_LIST_RESET });
  window.localStorage.removeItem("userInfo");
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_URL}users`,
      config
    );
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const saveShippingAddress = (shipping, token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CART_SAVE_SHIPPING_ADDRESS_REQUEST,
    });

    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token ?? token}`,
      },
    };
    const {data} = await axios.post(
      `${process.env.REACT_APP_API_URL_URL}users/shipping`,
      { shipping },
      config
    );
    dispatch({
      type: CART_SAVE_SHIPPING_ADDRESS_SUCCESS, data
    });
  } catch (error) {
    dispatch({
      type: CART_SAVE_SHIPPING_ADDRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const saveContactData =
  ({ phone, name }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_SAVE_CONTACT_DATA_REQUEST,
      });

      const {
        userLogIn: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_API_URL_URL}users/contact`,
        { phone, name },
        config
      );
      dispatch({
        type: CART_SAVE_CONTACT_DATA_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: CART_SAVE_CONTACT_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const detailsUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_URL}admin/usersDetails/${id}`,
      config
    );
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addToWish = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TO_WISHLIST_REQUEST,
    });

    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(
      `${process.env.REACT_APP_API_URL_URL}wishlist/${id}`,
      {},
      config
    );
    dispatch({ type: ADD_TO_WISHLIST_SUCCESS });
  } catch (error) {
    dispatch({
      type: ADD_TO_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeToWish = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_WISHLIST_REQUEST,
    });
    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(
      `${process.env.REACT_APP_API_URL_URL}wishlist/${id}`,
      {},
      config
    );
    dispatch({ type: REMOVE_WISHLIST_SUCCESS });
  } catch (error) {
    dispatch({
      type: REMOVE_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWishes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_WISHLIST_REQUEST,
    });
    const {
      userLogIn: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_URL}wishlist`,
      config
    );
    dispatch({ type: LIST_WISHLIST_SUCCESS, payload: data.wishlist });
  } catch (error) {
    dispatch({
      type: LIST_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserData =
  (token, userData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DATA_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL_URL}users`,
        userData,
        config
      );
      dispatch({ type: USER_DATA_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_DATA_UPDATE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
