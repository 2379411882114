import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { logOut } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
const DeskTopMenus = ({ userInfos }) => {
  const dispatch = useDispatch();
  return (
    <nav className="main-menu desktop-menu">
      <ul>
        {/* <li className="menu-item">
          <NavLink to="/products">Commander</NavLink>
        </li> */}
        {/* <li className="menu-item has-children">
          <NavLink to="#">
            Commander
            <span className="dd-trigger">
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="/">Commander</NavLink>
            </li>
            <li>
              <NavLink to="index-2">Home Fastfood</NavLink>
            </li>
            <li>
              <NavLink to="index-3">Home Seafood</NavLink>
            </li>
          </ul>
        </li> */}
        {userInfos && (
          <li className="menu-item has-children">
            <NavLink to="#">
              Mon Compte
              <span className="dd-trigger">
                <i className="far fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/account">Mon compte</NavLink>
              </li>
              <li>
                <NavLink to="/orders">Mes commandes</NavLink>
              </li>
              <li>
                <NavLink to="/wishlist">Mes favoris</NavLink>
              </li>
              <li>
                <NavLink to="/" onClick={() => dispatch(logOut())}>
                  Se déconnecter
                </NavLink>
              </li>
            </ul>
          </li>
        )}
        <li className="menu-item has-children">
          <NavLink to="#">
            A propos de nous
            <span className="dd-trigger">
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu">
            {/* <li>
              <NavLink to="menu-fastfood">Notre carte</NavLink>
            </li> */}
            <li className="menu-item">
              <NavLink to="/gallery">Gallerie</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/contact">Nous contacter</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="about">A propos de nous</NavLink>
            </li>
          </ul>
        </li>

        {/* <li className="menu-item has-children">
          <NavLink to="#">
            Blog
            <span className="dd-trigger">
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="blog-standard">Blog Standard</NavLink>
            </li>
            <li>
              <NavLink to="blog-details">Blog Details</NavLink>
            </li>
          </ul>
        </li>
        <li className="menu-item has-children">
          <NavLink to="#">
            Pages
            <span className="dd-trigger">
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="gallery">Our Gallery</NavLink>
            </li>
            <li>
              <NavLink to="chefs">Our Chefs</NavLink>
            </li>
            <li>
              <NavLink to="history">Our History</NavLink>
            </li>
            <li>
              <NavLink to="reservations">Reservations</NavLink>
            </li>
            <li>
              <NavLink to="404">404</NavLink>
            </li>
            <li>
              <NavLink to="faq">Faq</NavLink>
            </li>
            <li>
              <NavLink to="contact">Contact</NavLink>
            </li>
          </ul>
        </li> */}
      </ul>
    </nav>
  );
};

const MobileMenu = ({ userInfos }) => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const active = (value) => setActiveMenu(value === activeMenu ? null : value),
    activeIcon = (value) => (activeMenu === value ? "sub-menu-open" : ""),
    activeSubMenu = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" };

    const close = () => {
      try {
        const overlay = document.getElementsByClassName("nav-overlay")[0];
        overlay.classList.remove('active');
        const menu = document.getElementsByClassName("nav-menu")[0];
        menu.classList.remove('menu-on');
      }catch(e) {
        console.log(e);
      }
    } 
  return (
    <nav className="main-menu mobile-menu">
      <ul>
        <li className="menu-item" >
          <NavLink to="/" onClick={close}>Accueil</NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/products" onClick={close}>Commander</NavLink>
        </li>

        <li className="menu-item has-children">
          <NavLink to="#" onClick={() => active("home")}>
            Mon resto
            <span className={`dd-trigger ${activeIcon("home")}`}>
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu" style={activeSubMenu("home")}>
            {/* <li className="menu-item">
              <NavLink to="/menu">Notre carte</NavLink>
            </li> */}
            <li className="menu-item">
              <NavLink to="/gallery" onClick={close}>Gallerie</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/contact" onClick={close}>Nous contacter</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="about" onClick={close}>A propos de nous</NavLink>
            </li>
          </ul>
        </li>

        {userInfos && (
          <li className="menu-item has-children">
            <NavLink to="#" onClick={() => active("account")}>
              Mon compte
              <span className={`dd-trigger ${activeIcon("account")}`}>
                <i className="far fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu" style={activeSubMenu("account")}>
              <li>
                <NavLink to="/account" onClick={close}>Mon compte</NavLink>
              </li>
              <li>
                <NavLink to="/orders" onClick={close}>Mes commandes</NavLink>
              </li>
              <li>
                <NavLink to="/wishlist" onClick={close}>Mes favoris</NavLink>
              </li>
              <li>
                <NavLink to="/" onClick={() => dispatch(logOut())}>
                  Se déconnecter
                </NavLink>
              </li>
            </ul>
          </li>
        )}
        {userInfos && (
          <li className="menu-item">
            <NavLink to="/cart" onClick={close}>Mon panier</NavLink>
          </li>
        )}

        {/*<li className="menu-item has-children">
          <NavLink to="#" onClick={() => active("Menu")}>
            Menu
            <span className={`dd-trigger ${activeIcon("Menu")}`}>
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu" style={activeSubMenu("Menu")}>
            <li>
              <NavLink to="menu-fastfood">Menu Fastfood</NavLink>
            </li>
            <li>
              <NavLink to="menu-seafood">Menu Seafood</NavLink>
            </li>
          </ul>
        </li>
        <li className="menu-item">
          <NavLink to="about">About</NavLink>
        </li>
        <li className="menu-item has-children">
          <NavLink to="#" onClick={() => active("Shop")}>
            Shop
            <span className={`dd-trigger ${activeIcon("Shop")}`}>
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu" style={activeSubMenu("Shop")}>
            <li>
              <NavLink to="products">Our Products</NavLink>
            </li>
            <li>
              <NavLink to="product-details">Product Details</NavLink>
            </li>
          </ul>
        </li>
        <li className="menu-item has-children">
          <NavLink to="#" onClick={() => active("Blog")}>
            Blog
            <span className={`dd-trigger ${activeIcon("Blog")}`}>
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu" style={activeSubMenu("Blog")}>
            <li>
              <NavLink to="blog-standard">Blog Standard</NavLink>
            </li>
            <li>
              <NavLink to="blog-details">Blog Details</NavLink>
            </li>
          </ul>
        </li>
        <li className="menu-item has-children">
          <NavLink to="#" onClick={() => active("Pages")}>
            Pages
            <span className={`dd-trigger ${activeIcon("Pages")}`}>
              <i className="far fa-angle-down" />
            </span>
          </NavLink>
          <ul className="sub-menu" style={activeSubMenu("Pages")}>
            <li>
              <NavLink to="gallery">Our Gallery</NavLink>
            </li>
            <li>
              <NavLink to="chefs">Our Chefs</NavLink>
            </li>
            <li>
              <NavLink to="history">Our History</NavLink>
            </li>
            <li>
              <NavLink to="reservations">Reservations</NavLink>
            </li>
            <li>
              <NavLink to="404">404</NavLink>
            </li>
            <li>
              <NavLink to="faq">Faq</NavLink>
            </li>
            <li>
              <NavLink to="contact">Contact</NavLink>
            </li>
          </ul>
        </li>*/}
      </ul>
    </nav>
  );
};

const Menus = ({ userInfos }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const { width } = useWindowSize();
  useEffect(() => {
    if (width <= 1199) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, [width]);

  return (
    <Fragment>
      {isDesktop ? (
        <DeskTopMenus userInfos={userInfos} />
      ) : (
        <MobileMenu userInfos={userInfos} />
      )}
    </Fragment>
  );
};

export default Menus;
