export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET'

export const USER_ORDER_LIST_REQUEST = 'USER_ORDER_LIST_REQUEST'
export const USER_ORDER_LIST_SUCCESS = 'USER_ORDER_LIST_SUCCESS'
export const USER_ORDER_LIST_FAIL = 'USER_ORDER_LIST_FAIL'
export const USER_ORDER_LIST_RESET = 'USER_ORDER_LIST_RESET'

export const ADMIN_ORDER_LIST_REQUEST = 'ADMIN_ORDER_LIST_REQUEST'
export const ADMIN_ORDER_LIST_SUCCESS = 'ADMIN_ORDER_LIST_SUCCESS'
export const ADMIN_ORDER_LIST_FAIL = 'ADMIN_ORDER_LIST_FAIL'
export const ADMIN_ORDER_LIST_RESET = 'ADMIN_ORDER_LIST_RESET'

export const ORDER_STATUS_UPDATE_REQUEST = 'ORDER_STATUS_UPDATE_REQUEST'
export const ORDER_STATUS_UPDATE_SUCCESS = 'ORDER_STATUS_UPDATE_SUCCESS'
export const ORDER_STATUS_UPDATE_FAIL = 'ORDER_STATUS_UPDATE_FAIL'
export const ORDER_STATUS_UPDATE_RESET = 'ORDER_STATUS_UPDATE_RESET'

export const PAYMENT_STATUS_UPDATE_REQUEST = 'PAYMENT_STATUS_UPDATE_REQUEST'
export const PAYMENT_STATUS_UPDATE_SUCCESS = 'PAYMENT_STATUS_UPDATE_SUCCESS'
export const PAYMENT_STATUS_UPDATE_FAIL = 'PAYMENT_STATUS_UPDATE_FAIL'
export const PAYMENT_STATUS_UPDATE_RESET = 'PAYMENT_STATUS_UPDATE_RESET'
