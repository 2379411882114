import Menus from "./Menus";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/userActions";
import { redirect, NavLink, useNavigate } from "react-router-dom";
const Header3 = () => {
  const [bg, setBg] = useState("#ef5777");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogIn = useSelector((state) => state.userLogIn);
  const cart = useSelector((state) => state.cart);
  // const wishList = useSelector((state) => state.wishList);
  const { userInfo } = userLogIn;
  const { cartItems } = cart;
  // const { wishlistData = [] } = wishList;

  const logoutHandler = () => {
    dispatch(logOut());
    redirect("/login");
  };

  return (
    <header className="header-area transparent-header">
      {/*=== Header Navigation ===*/}
      <div className="header-navigation navigation-two navigation-white">
        <div className="nav-overlay" />
        <div className="container-fluid">
          {/*=== Primary Menu ===*/}
          <div className="primary-menu">
            {/*=== Site Branding ===*/}
            <div className="site-branding">
              <NavLink to="/">
                <p className="brand-logo">
                  <img
                    src="/assets/images/logo/logo-white.png"
                    alt="Site Logo"
                  />
                </p>
              </NavLink>
            </div>
            {/*=== Nav Inner Menu ===*/}
            <div className="nav-inner-menu">
              <div className="nav-menu">
                {/*=== Mobile Logo ===*/}
                <div className="mobile-logo mb-30 d-block d-xl-none text-center"></div>
                {/*=== Main Menu ===*/}
                <Menus userInfos={userInfo} />
              </div>
              {/*=== Nav right Item ===*/}
              <div className="nav-right-item d-flex align-items-center">
                <div className="nav-call-button">
                  <span>
                    <img src="/assets/images/call.png" alt="icon" />
                    <a href="tel:0986700934">09 86 70 09 34</a>
                  </span>
                </div>
                <div className="nav-call-button">
                  <span>
                    <img src="/assets/images/call.png" alt="icon" />
                    <a href="tel:0760732405">07 60 73 24 05</a>
                  </span>
                </div>
                <div className="menu-button d-xl-block d-none">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={
                      userInfo ? logoutHandler : () => navigate("/signin")
                    }>
                    <p className="main-btn btn-red">
                      {userInfo ? "Se déconnecter" : "Se Connecter"}
                      {userInfo && <i className="far fa-sign-out-alt" />}
                    </p>
                  </div>
                </div>
                {userInfo && (
                  <div className="menu-button d-xl-block d-none">
                    <NavLink to="/cart">
                      <p
                        className="main-btn btn-red"
                        style={{
                          paddingLeft: 5,
                          paddingRight: 15,
                          paddingTop: 22,
                          paddingBottom: 22,
                          position: "relative",
                        }}
                        onMouseEnter={() => setBg("black")}
                        onMouseLeave={() => setBg("#ef5777")}>
                        <i className="far fa-shopping-cart" />
                        <span
                          style={{
                            backgroundColor: bg,
                            padding: "2px 10px",
                            borderRadius: "54%",
                            position: "absolute",
                            top: -10,
                            left: 35,
                          }}>
                          {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                        </span>
                      </p>
                    </NavLink>
                  </div>
                )}
                {/*userInfo && (
                  <div className="menu-button d-xl-block d-none">
                    <NavLink to="/wishlist">
                      <p
                        className="main-btn btn-red"
                        style={{
                          paddingLeft: 5,
                          paddingRight: 15,
                          position: "relative",
                        }}
                        onMouseEnter={() => setBg("black")}
                        onMouseLeave={() => setBg("#ef5777")}>
                        <i className="far fa-star" />
                        <span
                          style={{
                            backgroundColor: bg,
                            padding: "2px 10px",
                            borderRadius: "54%",
                            position: "absolute",
                            top: -10,
                            left: 35,
                          }}>
                          {wishlistData?.length ?? 0}
                        </span>
                      </p>
                    </NavLink>
                  </div>
                        )*/}
                <div className="navbar-toggler" style={{ padding: "12px 5px" }}>
                  <span />
                  <span />
                  <span />
                </div>

                {userInfo && (
                  <div
                    className="navbar-toggler"
                    style={{ marginLeft: 10, position: "relative" }}>
                    <NavLink to="/cart">
                      <i
                        className="far fa-shopping-cart"
                        style={{ padding: "1px 4px", color: "white" }}
                      />
                    </NavLink>
                    <span
                      style={{
                        backgroundColor: bg,
                        // padding: "10px",
                        borderRadius: "54%",
                        position: "absolute",
                        paddingBlock: 15,
                        top: -20,
                        right: -10,
                        color: "white",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                    </span>
                  </div>
                )}

                {!userInfo && (
                  <div className="navbar-toggler" style={{ marginLeft: 10 }}>
                    <NavLink to="/signin">
                      <i
                        className="far fa-user"
                        style={{ padding: "1px 5px", color: "white" }}
                      />
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header3;
