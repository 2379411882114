import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PreLoader from "./layout/PreLoader";
import Layout from "./layout/Layout";
import { useDispatch } from "react-redux";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
} from "./redux/constants/userConstants";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { currentUser, logInUser } from "./redux/actions/userActions";
// import runOneSignal from "./config/onesignal";

import './App.css'

const HomeScreen = lazy(() => import("./screens/home"));
const ProductsScreen = lazy(() => import("./screens/products"));
// const ProductDetailsScreen = lazy(() => import("./screens/productDetails"));
const AccountScreen = lazy(() => import("./screens/account"));
const CompletePasswordScreen = lazy(() =>
  import("./screens/auth/complete/password")
);
const CompleteSignupScreen = lazy(() =>
  import("./screens/auth/complete/signup")
);
const SigninScreen = lazy(() => import("./screens/auth/signin"));
const SignupScreen = lazy(() => import("./screens/auth/signup"));
const CartScreen = lazy(() => import("./screens/cart"));
const AboutScreen = lazy(() => import("./screens/shared/about"));
const ContactScreen = lazy(() => import("./screens/shared/contact"));
const GalleryScreen = lazy(() => import("./screens/shared/gallery"));

const WishlistScreen = lazy(() => import("./screens/wishlist"));
const OrdersScreen = lazy(() => import("./screens/orders"));
const OrderDetailsScreen = lazy(() => import("./screens/orderDetails"));

const CheckoutScreen = lazy(() => import("./screens/checkout"));
const PlaceOrderScreen = lazy(() => import("./screens/placeorder"));
const MenuScreen = lazy(() => import("./screens/shared/menu"));

const E404Screen = lazy(() => import("./screens/shared/404"));



function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   runOneSignal().catch((e)=>{
  //     console.error(e);
  //   });
  // })

  // to check firebase auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        dispatch(logInUser(user));
        // const idTokenResult = await user.getIdTokenResult();

        // currentUser(idTokenResult.token)
        //   .then((res) => {
        //     dispatch({ type: USER_LOGIN_REQUEST });
        //     dispatch({
        //       type: USER_LOGIN_SUCCESS,
        //       payload: {
        //         name: res.name,
        //         email: res.email,
        //         token: idTokenResult.token,
        //         role: res.role,
        //         _id: res._id,
        //         shipping: res.shipping,
        //       },
        //     });
        //   })
        //   .catch((err) => {
        //     dispatch({ type: USER_LOGIN_FAIL, payload: err });
        //   });
      }
    });
    // cleanup
    return () => unsubscribe();
  }, [dispatch]);
  return (
    <Suspense fallback={<PreLoader />}>
      <Layout header={3}>
        <Routes>
          <Route path="/" element={<HomeScreen />} lazy={true} exact />
          <Route
            path="/products"
            element={<ProductsScreen />}
            lazy={true}
            exact
          />
          <Route
            path="/products/:productId"
            element={<ProductsScreen />}
            lazy={true}
            exact
          />
          <Route
            path="/account"
            element={<AccountScreen />}
            lazy={true}
            exact
          />
          <Route
            path="/complete/password"
            element={<CompletePasswordScreen />}
            lazy={true}
            exact
          />
          
          <Route path="/signin" element={<SigninScreen />} lazy={true} exact />
          <Route path="/signup" element={<SignupScreen />} lazy={true} exact />
          <Route
            path="/signup/complete"
            element={<CompleteSignupScreen />}
            lazy={true}
            exact
          />
          <Route path="/cart" element={<CartScreen />} lazy={true} exact />
          <Route path="/about" element={<AboutScreen />} lazy={true} exact />
          <Route
            path="/contact"
            element={<ContactScreen />}
            lazy={true}
            exact
          />
          <Route
            path="/gallery"
            element={<GalleryScreen />}
            lazy={true}
            exact
          />
          <Route path="/menu" element={<MenuScreen />} lazy={true} exact />
          <Route
            path="/wishlist"
            element={<WishlistScreen />}
            lazy={true}
            exact
          />
          <Route path="/orders" element={<OrdersScreen />} lazy={true} exact />
          <Route
            path="orders/:orderId"
            element={<OrderDetailsScreen />}
            lazy={true}
          />
          <Route
            path="/checkout"
            element={<CheckoutScreen />}
            lazy={true}
            exact
          />
          <Route
            path="/placeOrder"
            element={<PlaceOrderScreen />}
            lazy={true}
            exact
          />

          <Route path="*" element={<E404Screen />} />
          {/* 
          const CompletePasswordScreen = lazy(() => import("./screens/auth/complete/password"));
const CompleteSignupScreen = lazy(() => import("./screens/auth/complete/signup"));
const SigninScreen = lazy(() => import("./screens/auth/signin"));
const SignupScreen = lazy(() => import("./screens/auth/signup"));
const CartScreen = lazy(() => import("./screens/cart"));
const AboutScreen = lazy(() => import("./screens/shared/about"));
const ContactScreen = lazy(() => import("./screens/shared/contact"));
const GalleryScreen = lazy(() => import("./screens/shared/gallery"));; */}
          {/*<Route path="/register" component={RegisterScreen} exact />
            <Route path="/login" component={LoginScreen} />
            <Route path="/forgot/password" component={ForgetPasswordScreen} />
            <Route
              path="/register/complete"
              component={RegisterCompleteScreen}
            />
            <Route
              path="/user/orderhistory"
              component={OrderHistoryScreen}
              exact
            />
            <Route path="/user/wishlist" component={WishlistScreen} exact />
            <Route path="/user/password" component={PasswordScreen} exact />
            <Route path="/admin/userlist" component={UserListScreen} exact />
            <Route path="/admin/category" component={CategoryScreen} exact />
            <Route
              path="/admin/category/:slug/edit"
              component={CategoryEditScreen}
              exact
            />
            <Route
              path="/category/:slug"
              component={CategoryArchiveScreen}
              exact
            />
            <Route path="/admin/addon" component={AddonScreen} exact />
            <Route
              path="/admin/addon/:slug/edit"
              component={AddonEditScreen}
              exact
            />
            <Route path="/admin/products" component={ProductListScreen} exact />
            <Route
              path="/admin/product/create"
              component={ProductCreateScreen}
              exact
            />
            <Route
              path="/admin/product/:slug/edit"
              component={ProductEditScreen}
              exact
            />
            <Route path="/admin/attribute" component={AttributeScreen} exact />
            <Route
              path="/admin/attribute/:id/edit"
              component={AttributeEditScreen}
              exact
            />
            <Route
              path="/admin/variables"
              component={VariableCreateScreen}
              exact
            />
            <Route
              path="/admin/variable/:id/edit"
              component={VariableEditScreen}
              exact
            />
            <Route path="/admin/coupon" component={CouponScreen} />
            <Route path="/admin/manageorder" component={ManageOrderScreen} />
            <Route
              path="/product/:slug"
              component={SingleProductScreen}
              exact
            />
            <Route path="/cart" component={CartScreen} />
            <Route path="/checkout" component={CheckoutScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/order/:id" component={OrderDetailsScreen} />
    <Route path="/admin/user/:id" component={UserDetailsScreen} />*/}
        </Routes>
      </Layout>

      {/*<Footer />*/}
    </Suspense>
  );
}

export default App;
