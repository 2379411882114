export const NEWSLETTER_SUBSCRIBE_REQUEST = "NEWSLETTER_SUBSCRIBE_REQUEST";
export const NEWSLETTER_SUBSCRIBE_SUCCESS = "NEWSLETTER_SUBSCRIBE_SUCCESS";
export const NEWSLETTER_SUBSCRIBE_FAIL = "NEWSLETTER_SUBSCRIBE_FAIL";

export const NEWSLETTER_UNSUBSCRIBE_REQUEST = "NEWSLETTER_UNSUBSCRIBE_REQUEST";
export const NEWSLETTER_UNSUBSCRIBE_SUCCESS = "NEWSLETTER_UNSUBSCRIBE_SUCCESS";
export const NEWSLETTER_UNSUBSCRIBE_FAIL = "NEWSLETTER_UNSUBSCRIBE_FAIL";

export const NEWSLETTER_ISSUBSCRIBE_REQUEST = "NEWSLETTER_ISSUBSCRIBE_REQUEST";
export const NEWSLETTER_ISSUBSCRIBE_SUCCESS = "NEWSLETTER_ISSUBSCRIBE_SUCCESS";
export const NEWSLETTER_ISSUBSCRIBE_FAIL = "NEWSLETTER_ISSUBSCRIBE_FAIL";
