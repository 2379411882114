export const ATTRIBUTE_CREATE_REQUEST = 'ATTRIBUTE_CREATE_REQUEST'
export const ATTRIBUTE_CREATE_SUCCESS = 'ATTRIBUTE_CREATE_SUCCESS'
export const ATTRIBUTE_CREATE_FAIL = 'ATTRIBUTE_CREATE_FAIL'

export const ATTRIBUTE_LIST_REQUEST = 'ATTRIBUTE_LIST_REQUEST'
export const ATTRIBUTE_LIST_SUCCESS = 'ATTRIBUTE_LIST_SUCCESS'
export const ATTRIBUTE_LIST_FAIL = 'ATTRIBUTE_LIST_FAIL'

export const ATTRIBUTE_DELETE_REQUEST = 'ATTRIBUTE_DELETE_REQUEST'
export const ATTRIBUTE_DELETE_SUCCESS = 'ATTRIBUTE_DELETE_SUCCESS'
export const ATTRIBUTE_DELETE_FAIL = 'ATTRIBUTE_DELETE_FAIL'

export const ATTRIBUTE_DETAILS_REQUEST = 'ATTRIBUTE_DETAILS_REQUEST'
export const ATTRIBUTE_DETAILS_SUCCESS = 'ATTRIBUTE_DETAILS_SUCCESS'
export const ATTRIBUTE_DETAILS_FAIL = 'ATTRIBUTE_DETAILS_FAIL'
export const ATTRIBUTE_DETAILS_RESET = 'ATTRIBUTE_DETAILS_RESET'

export const ATTRIBUTE_UPDATE_REQUEST = 'ATTRIBUTE_UPDATE_REQUEST'
export const ATTRIBUTE_UPDATE_SUCCESS = 'ATTRIBUTE_UPDATE_SUCCESS'
export const ATTRIBUTE_UPDATE_FAIL = 'ATTRIBUTE_UPDATE_FAIL'
export const ATTRIBUTE_UPDATE_RESET = 'ATTRIBUTE_UPDATE_RESET'
