import { NavLink } from "react-router-dom";
import { useAlert } from "react-alert";
import { axiosApiInstance as axios } from "../../config/axios";
import { useState } from "react";
import { toast } from "react-toastify";

const DefaultFooter = () => {
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const subscribe = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL_URL}newsletter/subscribe`, {
        email,
      })
      .then(() => {
        toast("✅️Vous avez été souscris à notre newsletter !", {theme: 'dark'});
      })
      .catch((e) => {
        toast(`❗${e?.message ?? 'Erreur lors de la souscription !'}`, {theme: 'dark'});
      });
  };

  return (
    <footer className="footer-dark dark-black-bg text-white p-r z-1 pt-80">
      {/*=== Footer Widget ===*/}
      <div className="footer-widget-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget about-company-widget pr-lg-30 mb-40 wow fadeInUp">
                <div className="footer-content">
                  <NavLink to="/">
                    <p className="footer-logo mb-30">
                      <img
                        src="/assets/images/logo/logo-white.png"
                        alt="Brand Logo"
                      />
                    </p>
                  </NavLink>
                  <p>Abonnez vous à notre newsletter</p>
                  <form onSubmit={subscribe}>
                    <div className="form_group">
                      <label style={{ cursor: "pointer" }} onClick={subscribe}>
                        <i className="far fa-arrow-right" />
                      </label>
                      <input
                        type="email"
                        className="form_control"
                        placeholder="Adresse mail"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-widget footer-nav-widget pl-lg-30 mb-40 wow fadeInDown">
                <h4 className="widget-title">Raccourcis</h4>
                <div className="footer-nav-content">
                  <ul className="footer-nav">
                    <li>
                      <NavLink to="/products">Commander</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/menu">Notre Carte</NavLink>
                    </li> */}
                    <li>
                      <NavLink to="/contact">Contact</NavLink>
                    </li>
                    <li>
                      <NavLink to="/gallery">Gallerie</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">A propos de nous</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-nav-widget pl-lg-30 mb-40 wow fadeInUp">
                <h4 className="widget-title">Raccourcis</h4>
                <div className="footer-nav-content">
                  <ul className="footer-nav">
                    <li>
                      <NavLink to="/products?category=burger">Burger</NavLink>
                    </li>
                    <li>
                      <NavLink to="/products?category=naan">Naan</NavLink>
                    </li>
                    <li>
                      <NavLink to="/products?category=tacos">Tacos</NavLink>
                    </li>
                    <li>
                      <NavLink to="/products?category=extras">Extras</NavLink>
                    </li>
                    <li>
                      <NavLink to="/products?category=boissons">
                        Boissons
                      </NavLink>
                    </li>
                  </ul>
                  {/* <ul className="footer-nav">
                    <li>
                      <NavLink to="#">Potato</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Organic Juice</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Lemon Juice</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">MuTton Tikka</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Seafoods</NavLink>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget contact-info-widget pl-lg-70 mb-40 wow fadeInDown">
                <div className="contact-info-box mb-20">
                  <span className="title">Contact:</span>
                  <h3>
                    <a href="tel:0986700934">09.86.70.09.34</a>
                  </h3>
                  <h3>
                    <a href="tel:0760732405">07.60.73.24.05</a>
                  </h3>
                  <p>
                    <a href="mailto:contact@freshburgerbilly.fr">
                      contact@freshburgerbilly.fr
                    </a>
                  </p>
                </div>
                <div className="contact-info-box mb-20">
                  <span className="title">Location :</span>
                  <p>133 Av de la république, 62420 billy-montigny</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=== Copyright Area ===*/}
      <div className="copyright-area border-top-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright-text">
                <p>© 2024 Fresh Burger, Droits Reservés</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="copyright-nav float-lg-end">
                <ul>
                  <li>
                    <NavLink to="#">Confidentialités</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/menu">Notre Carte</NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default DefaultFooter;
